import { createUrlPathFromArray } from "~utils/create_url_path_from_array/create_url_path_from_array";
import { createInternalLink } from "~utils/link_utils/createInternalLink/createInternalLink";

export default function getFirstLink({
  basePath,
  currentFilter,
  currentFilterType,
}) {
  if (currentFilter && currentFilterType) {
    const linkPath = createUrlPathFromArray([
      basePath,
      currentFilterType,
      currentFilter,
    ]);
    return createInternalLink(linkPath);
  }

  return createInternalLink(`${basePath}`);
}
