import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import RscHbCardReport from "~components/molecules/rsc_hb_card/rsc_hb_card_report/rsc_hb_card_report";
import RscHbInPageContactForm from "~components/molecules/rsc_hb_in_page_contact_form/rsc_hb_in_page_contact_form";
import RscHbPageHeader from "~components/molecules/rsc_hb_page_header/rsc_hb_page_header";
import RscHbPageWrapper from "~components/molecules/rsc_hb_page_wrapper/rsc_hb_page_wrapper";
import RscHbPaginatedList from "~components/molecules/rsc_hb_paginated_list/rsc_hb_paginated_list";
import usePageData from "~hooks/use_page_data/use_page_data";
import {
  ASSET,
  RESOURCE_HUB_SECTION,
  STORYBLOK_ENTRY,
  STORYBLOK_ENTRY_ARRAY,
} from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";

const GRID_SPLIT = { XXL: "4", L: "2", XXS: "1" };

export default function TemplateListSpecialisedReports(props) {
  const { story, pageContext, entryAssets } = usePageData(props);

  const {
    breadcrumbsItems,
    contactForm,
    currentPage,
    lang,
    sections,
    pagePath,
    subNavData,
    UIStrings,
    navigationConfig,
  } = pageContext;

  const { paginationSectionObject } = sections;
  const { title, subtitle, allItemsTitle, navigationSlug } = story;

  return (
    <TemplateGlobalEntry
      subNavData={subNavData}
      entryAssets={entryAssets}
      headerColor="blue"
      pagePath={pagePath}
      programmaticPageTitle={title}
      isListPage
      {...props}
    >
      <RscHbPageWrapper
        items={subNavData}
        lang={lang}
        level1ActiveItem="guides-and-reports"
        level2ActiveItem={navigationSlug}
        navigationConfig={navigationConfig}
      >
        <RscHbPageHeader
          breadcrumbsItems={breadcrumbsItems}
          title={title}
          description={subtitle}
        />

        {/* ————— ALL SPECIALISED REPORTS SECTION ————— */}
        <RscHbPaginatedList
          basePath={pagePath}
          contentCardComponent={RscHbCardReport}
          ctaComponent={null}
          currentPage={currentPage}
          gridSplit={GRID_SPLIT}
          items={paginationSectionObject.items}
          itemsPerPage={999}
          pageCount={1}
          allItemsTitle={allItemsTitle}
          UIStrings={UIStrings}
        />
      </RscHbPageWrapper>
      <RscHbInPageContactForm lang={lang} {...contactForm} />
    </TemplateGlobalEntry>
  );
}

TemplateListSpecialisedReports.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
    items: STORYBLOK_ENTRY_ARRAY,
    assets: PropTypes.shape({
      nodes: PropTypes.arrayOf(ASSET),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    breadcrumbsItems: PropTypes.arrayOf(PropTypes.shape({})),
    contactForm: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
    UIStrings: PropTypes.shape({}),
    currentPage: PropTypes.number,
    sections: PropTypes.shape({
      paginationSectionObject: RESOURCE_HUB_SECTION,
      featuredItem: RESOURCE_HUB_SECTION,
    }),
    itemsPerPage: PropTypes.number,
    lang: PropTypes.string,
    pageCount: PropTypes.number,
    pagePath: PropTypes.string,
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

// TODO: get page specific metadata here
export const query = graphql`
  query TemplateListSpecialisedReportQuery(
    $id: String
    $langRegex: String
    $allCoverIDs: [String]
  ) {
    ...resourceHubStory
    ...global
    ...resourceHubCoverAssets
  }
`;
