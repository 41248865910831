export default function filterPageArray({ pageCount, currentPage }) {
  // Create array from pageCount, add 1 to each item to offset 0 index
  const array = Array(pageCount)
    .fill(1)
    .map((_, index) => {
      return index + 1;
    });

  if (pageCount <= 5) {
    return array;
  }

  // if (currentPage < 10) {
  //   return array.slice(0, 10);
  // }

  let lowerBoundary = 1;

  if (currentPage - 3 > 1) {
    lowerBoundary = currentPage - 3;
  }

  if (currentPage > pageCount - 3) {
    lowerBoundary = pageCount - 5;
  }

  let upperBoundary = currentPage + 2;

  if (currentPage + 2 >= pageCount) {
    upperBoundary = pageCount;
  }

  // if (currentPage > pageCount - 5) {
  //   upperBoundary = pageCount;
  // }

  if (currentPage >= 5) {
    return array.slice(lowerBoundary, upperBoundary);
  }

  return array.slice(0, 5);
}
