// ToDo: (AMG) Write tests for CTA placement logic

export default function getCtaPosition({
  itemsPerPage,
  items,
  pageCount,
  currentPage,
}) {
  const shouldRenderCtaBeforeList =
    items.length < itemsPerPage || !pageCount || currentPage === pageCount;

  // Pass prev condition && is on multiple of 2
  const shouldRenderCtaInMiddleOfList =
    !shouldRenderCtaBeforeList && currentPage % 2 !== 0;

  // Fallback
  const shouldRenderCtaAfterList =
    !shouldRenderCtaBeforeList && !shouldRenderCtaInMiddleOfList;

  return {
    shouldRenderCtaBeforeList,
    shouldRenderCtaInMiddleOfList,
    shouldRenderCtaAfterList,
  };
}
