import React, { useMemo } from "react";
import PropTypes from "prop-types";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import RscHbCtaNewsletter from "~components/molecules/rsc_hb_cta/rsc_hb_cta_newsletter/rsc_hb_cta_newsletter";
import RscHbPagination from "~components/molecules/rsc_hb_pagination/rsc_hb_pagination";
import { STORYBLOK_ENTRY_ARRAY } from "../../../prop_types";
import getCtaPosition from "./helper_functions/get_cta_position/get_cta_position";
import getPaginationTitle from "./helper_functions/get_pagination_title/get_pagination_title";
import {
  ctaWrapperStyle,
  headingStyle,
  marginBottomStyle,
  paginationWrapper,
  wrapperStyle,
} from "./rsc_hb_paginated_list.module.scss";

function RscHbPaginatedList({
  allItemsTitle,
  basePath,
  categoryItemsTitle,
  categoryTitle,
  contentCardComponent,
  ctaComponent,
  currentPage,
  gridSplit,
  items,
  itemsPerPage,
  pageCount,
  UIStrings,
}) {
  const paginationTitle = useMemo(() => {
    return getPaginationTitle({
      allItemsTitle,
      categoryItemsTitle,
      categoryTitle,
      UIStrings,
      currentPage,
    });
  }, [
    UIStrings,
    allItemsTitle,
    categoryItemsTitle,
    categoryTitle,
    currentPage,
  ]);

  // Create react components from prop
  // Must begin with uppercase
  const ContentCardComponent = contentCardComponent;
  const CTAComponent = ctaComponent;

  // This component is responsible for creating a nice,"flowing"
  // layout for a grid of articles, with an appropriate CTA nestled
  // in an an appropriate placing within the hierarchy
  const {
    shouldRenderCtaBeforeList,
    shouldRenderCtaInMiddleOfList,
    shouldRenderCtaAfterList,
  } = getCtaPosition({
    itemsPerPage,
    items,
    pageCount,
    currentPage,
  });

  const itemsHalfwayIndex = Math.floor(items.length / 2);
  const shouldSplitItems = shouldRenderCtaInMiddleOfList;

  return (
    <>
      <div data-cy="resource_hub_paginated_list" className={wrapperStyle}>
        <div className={paginationWrapper}>
          {/* Conditionally render a CTA before grid items */}
          {ctaComponent && shouldRenderCtaBeforeList && (
            <div className={[ctaWrapperStyle, marginBottomStyle].join(" ")}>
              <CTAComponent />
            </div>
          )}
          <div className={headingStyle} key="cta-1">
            {paginationTitle && (
              <Heading visualLevel={5} seoLevel={3}>
                {paginationTitle}
              </Heading>
            )}
          </div>

          {/* ——— RENDER FIRST HALF OF ITEMS ———— */}
          <GridNew split={gridSplit}>
            {items.map((item, index) => {
              if (shouldSplitItems && index < itemsHalfwayIndex) {
                return (
                  <ContentCardComponent key={item.title} {...item} block />
                );
              }
              if (!shouldSplitItems) {
                return (
                  <ContentCardComponent key={item.title} {...item} block />
                );
              }
              return null;
            })}
          </GridNew>

          {/* ——— HALFWAY POINT ———— */}
          {/* ——— Conditionally render a CTA at the halfway point ———— */}
          {ctaComponent && shouldRenderCtaInMiddleOfList && (
            <div className={ctaWrapperStyle} key="cta-2">
              <CTAComponent />
            </div>
          )}

          {/* ——— RENDER SECOND HALF OF ITEMS ———— */}
          <GridNew split={gridSplit}>
            {items.map((item, index) => {
              if (shouldSplitItems && index > itemsHalfwayIndex - 1) {
                return (
                  <ContentCardComponent key={item.title} {...item} block />
                );
              }
              return null;
            })}
          </GridNew>
          {/* Conditionally render a CTA after grid items */}
          {ctaComponent && shouldRenderCtaAfterList && (
            <div className={ctaWrapperStyle} key="cta-3">
              <CTAComponent />
            </div>
          )}
        </div>
        {pageCount > 1 && (
          <RscHbPagination
            basePath={basePath}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        )}
      </div>
    </>
  );
}

RscHbPaginatedList.defaultProps = {
  categoryTitle: null,
  categoryItemsTitle: null,
  ctaComponent: RscHbCtaNewsletter,
  gridSplit: { XXL: "3", XL: "2", M: "1" },
  hasFeatured: false,
  itemsPerPage: 12,
};

RscHbPaginatedList.propTypes = {
  allItemsTitle: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  categoryItemsTitle: PropTypes.string,
  categoryTitle: PropTypes.string,
  contentCardComponent: PropTypes.func.isRequired,
  ctaComponent: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  gridSplit: PropTypes.shape({}),
  hasFeatured: PropTypes.bool,
  items: STORYBLOK_ENTRY_ARRAY.isRequired,
  itemsPerPage: PropTypes.number,
  pageCount: PropTypes.number.isRequired,
  UIStrings: PropTypes.shape({}).isRequired,
};

export default RscHbPaginatedList;
