import { createUrlPathFromArray } from "~utils/create_url_path_from_array/create_url_path_from_array";
import { createInternalLink } from "~utils/link_utils/createInternalLink/createInternalLink";

export default function previousLink({
  currentPage,
  basePath,
  currentFilterType,
  currentFilter,
}) {
  if (currentPage >= 3) {
    if (currentFilter && currentFilterType) {
      const linkPath = createUrlPathFromArray([
        basePath,
        currentFilterType,
        currentFilter,
        currentPage - 1,
      ]);
      return createInternalLink(linkPath);
    }
    const linkPath = createUrlPathFromArray([basePath, currentPage - 1]);
    return createInternalLink(linkPath);
  }
  if (currentPage === 2) {
    if (currentFilter && currentFilterType) {
      const linkPath = createUrlPathFromArray([
        basePath,
        currentFilterType,
        currentFilter,
      ]);
      return createInternalLink(linkPath);
    }
    return createInternalLink(basePath);
  }

  if (currentPage === 1) {
    return createInternalLink(basePath);
  }
  const linkPath = createUrlPathFromArray([basePath, currentPage - 1]);
  return createInternalLink(linkPath);
}
