import React from "react";
import PropTypes from "prop-types";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import pageNumberRegex from "../../../utils/regex_strings/page_number_regex/page_number_regex";
import {
  filterPageArray,
  getFirstLink,
  getLastLink,
  getNextLink,
  getPageNumberLink,
  getPreviousLink,
} from "./helper_functions";
import {
  activeStyle,
  iconLeft,
  iconRight,
  paginationItem,
  paginationWrapper,
} from "./rsc_hb_pagination.module.scss";

function RscHbPagination({
  basePath,
  pageCount,
  currentPage,
  currentFilter,
  currentFilterType,
}) {
  // replace page number with trailing slash
  const basePathWithoutPageNumber = basePath.replace(pageNumberRegex, "/");

  const firstLink = getFirstLink({
    basePath: basePathWithoutPageNumber,
    currentFilter,
    currentFilterType,
  });

  const lastLink = getLastLink({
    basePath: basePathWithoutPageNumber,
    pageCount,
    currentFilter,
    currentFilterType,
  });

  const previousLink = getPreviousLink({
    currentPage,
    basePath: basePathWithoutPageNumber,
    currentFilter,
    currentFilterType,
  });
  const nextLink = getNextLink({
    currentPage,
    pageCount,
    basePath: basePathWithoutPageNumber,
    currentFilter,
    currentFilterType,
  });

  const pageArray = filterPageArray({ pageCount, currentPage });

  return (
    <div data-testid="list-page-pagination" className={paginationWrapper}>
      {currentPage > 2 && (
        <LinkWrapper link={firstLink}>
          <svg className={iconLeft}>
            <use xlinkHref="/images/icon_angle_double.svg#icon" />
          </svg>
        </LinkWrapper>
      )}
      {currentPage > 1 && (
        <LinkWrapper link={previousLink}>
          <svg className={iconLeft}>
            <use xlinkHref="/images/icon_angle.svg#icon" />
          </svg>
        </LinkWrapper>
      )}

      {pageArray.map((pageNumber) => {
        const link = getPageNumberLink({
          pageNumber,
          basePath: basePathWithoutPageNumber,
          currentFilter,
          currentFilterType,
        });
        if (pageNumber === currentPage) {
          const classNames = [paginationItem, activeStyle];
          return (
            <button
              data-cy={`pagination_button_${pageNumber}`}
              disabled
              type="button"
              className={classNames.join(" ")}
              key={`pagination-button-${pageNumber}`}
            >
              {pageNumber}
            </button>
          );
        }
        return (
          <LinkWrapper link={link} key={`pageNumber-${pageNumber}`}>
            <button
              data-cy={`pagination_button_${pageNumber}`}
              type="button"
              className={paginationItem}
            >
              {pageNumber}
            </button>
          </LinkWrapper>
        );
      })}
      {currentPage < pageCount && (
        <LinkWrapper link={nextLink}>
          <svg className={iconRight}>
            <use xlinkHref="/images/icon_angle.svg#icon" />
          </svg>
        </LinkWrapper>
      )}
      {currentPage < pageCount - 1 && (
        <LinkWrapper link={lastLink}>
          <svg className={iconRight}>
            <use xlinkHref="/images/icon_angle_double.svg#icon" />
          </svg>
        </LinkWrapper>
      )}
    </div>
  );
}

RscHbPagination.defaultProps = {
  currentFilterType: null,
  currentFilter: null,
};

RscHbPagination.propTypes = {
  basePath: PropTypes.string.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  currentFilterType: PropTypes.string,
  currentFilter: PropTypes.string,
};

export default RscHbPagination;
