import { createUrlPathFromArray } from "~utils/create_url_path_from_array/create_url_path_from_array";
import { createInternalLink } from "~utils/link_utils/createInternalLink/createInternalLink";

export default function getNextLink({
  currentPage,
  pageCount,
  basePath,
  currentFilterType,
  currentFilter,
}) {
  // if is on/above second last page
  if (currentPage >= pageCount - 1) {
    if (currentFilter && currentFilterType) {
      const linkPath = createUrlPathFromArray([
        basePath,
        currentFilterType,
        currentFilter,
        pageCount,
      ]);
      return createInternalLink(linkPath);
    }
    const linkPath = createUrlPathFromArray([basePath, pageCount]);
    return createInternalLink(linkPath);
  }
  if (currentFilter && currentFilterType) {
    const linkPath = createUrlPathFromArray([
      basePath,
      currentFilterType,
      currentFilter,
      currentPage + 1,
    ]);
    return createInternalLink(linkPath);
  }

  const linkPath = createUrlPathFromArray([basePath, currentPage + 1]);
  return createInternalLink(linkPath);
}
