export default function getPaginationTitle({
  allItemsTitle,
  categoryItemsTitle,
  categoryTitle,
  UIStrings,
  currentPage,
}) {
  const { uiPageTitle } = UIStrings;

  const allItemsTitleTrimmed = allItemsTitle?.trim();
  const categoryItemsTitleTrimmed = categoryItemsTitle?.trim();
  const categoryTitleTrimmed = categoryTitle?.trim();
  const uiPageTitleTrimmed = uiPageTitle?.trim().toLowerCase();

  // ——————————————————————————————————————————————————————————————————————————————
  //      ON A CATEGORY PAGE
  // ——————————————————————————————————————————————————————————————————————————————

  if (categoryTitleTrimmed && categoryItemsTitleTrimmed) {
    // ——————————————————————————————————————————————————————————————
    //      ABBREVIATED CATEGORY TITLE WITH PAGE NUMBER
    // ——————————————————————————————————————————————————————————————
    if (currentPage > 1) {
      return `"${categoryTitleTrimmed}", ${uiPageTitleTrimmed} ${currentPage}`;
    }

    return `${categoryItemsTitleTrimmed} "${categoryTitleTrimmed}"`;
  }

  // ——————————————————————————————————————————————————————————————————————————————
  //      NOT ON A CATEGORY PAGE
  // ——————————————————————————————————————————————————————————————————————————————

  if (currentPage > 1) {
    return `${allItemsTitleTrimmed}, ${uiPageTitleTrimmed} ${currentPage}`;
  }

  return allItemsTitleTrimmed;
}
