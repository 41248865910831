import { createUrlPathFromArray } from "~utils/create_url_path_from_array/create_url_path_from_array";
import { createInternalLink } from "~utils/link_utils/createInternalLink/createInternalLink";

export default function getPageNumberLink({
  pageNumber,
  basePath,
  currentFilter,
  currentFilterType,
}) {
  if (pageNumber > 1) {
    if (currentFilter && currentFilterType) {
      const linkPath = createUrlPathFromArray([
        basePath,
        currentFilterType,
        currentFilter,
        pageNumber,
      ]);
      return createInternalLink(linkPath);
    }
    const linkPath = createUrlPathFromArray([basePath, pageNumber]);
    return createInternalLink(linkPath);
  }
  if (pageNumber <= 1) {
    if (currentFilter && currentFilterType) {
      const linkPath = createUrlPathFromArray([
        basePath,
        currentFilterType,
        currentFilter,
      ]);
      return createInternalLink(linkPath);
    }
    return createInternalLink(basePath);
  }
  return null;
}
